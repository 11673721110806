import Swiper from 'swiper';

export default class {

  constructor(elem, param = {}) {
    this.domElem = elem;
    this.param = {
      speed: param.speed || 400,
      spaceBetween: param.spaceBetween || 0,
      slidesPerView: param.slidesPerView || 1,
      autoplay: {
        delay: param.delay || 5000,
      },
      loop: param.loop || true,
    };
    this.param = Object.assign(this.param, param);
    this.swiperContainer = elem.querySelector('.swiper-container');
    if (this.domElem.classList.contains('swiper-container')) {
      this.swiperContainer = this.domElem;
    }
    this.domElem.initedCarousel = this.initSwiper(this.swiperContainer);
  }

  initSwiper($container) {
    return new Swiper($container, this.param);
  }

}
