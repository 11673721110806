export default class {

  constructor(elem, param = {}) {
    this.domElem = elem;
    this.arPeoples = elem.querySelectorAll('.b-team__item');
    if (this.arPeoples) {
      this.delegatePeopleLink(this.arPeoples);
    }
  }

  delegatePeopleLink(arLinks) {
    arLinks.forEach($link => {


      $link.addEventListener('mouseenter', () => {
        this.mouseOnDropdownLink($link);
      });

      $link.addEventListener('mouseleave', () => {
        this.mouseOutDropdownLink($link);
      });

    });
  }

  mouseOnDropdownLink($link) {
    let $parent = $link.closest('.b-team__list');
    if ($parent) {
      $parent.querySelectorAll('.b-team__item').forEach($item => {
        $item.classList.add('hovered');
      });
      $link.classList.remove('hovered');
    }
  }

  mouseOutDropdownLink($link) {
    this.arPeoples.forEach($item => {
      $item.classList.remove('hovered');
    });
  }


}
