export default class {

  constructor(elem, param = {}) {
    this.domElem = elem;
    this.transition = param.transition || 450;
    this.menu = elem.querySelector(param.menuClass || '.b-header__menu');
    this.burger = elem.querySelector('.js-burger');
    this.close = elem.querySelector('.js-close');
    this.burgerMenu = elem.querySelector('.b-header__burger-menu');
    this.searchButton = elem.querySelectorAll('.js-get-search');
    this.search = elem.querySelector('.js-form-search');
    this.searchClose = document.querySelectorAll('.js-search-close');

    if (this.searchButton) {
      this.searchButton.forEach($button => {
        $button.addEventListener('click', event => {
          event.preventDefault();
          this.delayedAnimation(this.search);
        });
      });
      if (this.searchClose) {
        this.searchClose.forEach($button => {
          $button.addEventListener('click', event => {
            event.preventDefault();
            this.delayedAnimation(this.search, true);
          });
        });
      }
    }

    if (this.burger) {
      this.burger.addEventListener('click', event => {
        event.preventDefault();
        this.domElem.classList.add('b-header_active');
        this.delayedAnimation(this.burgerMenu);
        document.body.classList.add('fixes');
      });
      this.close.addEventListener('click', event => {
        event.preventDefault();
        this.domElem.classList.remove('b-header_active');
        this.delayedAnimation(this.burgerMenu, true);
        document.body.classList.remove('fixes');
      });
    }
    if (this.menu) {
      this.arDropdownLinks = this.domElem.querySelectorAll('.js-item');

      if (this.arDropdownLinks) {
        this.delegateDropdownLink(this.arDropdownLinks);
      }
    }
  }

  delegateDropdownLink(arLinks) {

    arLinks.forEach($link => {

      let timeout;

      $link.subMenu = document.querySelector($link.getAttribute('data-target'));

      $link.addEventListener('mouseenter', () => {
        clearTimeout(timeout);
        this.arDropdownLinks.forEach($item => {
          if ($link !== $item) {
            $item.classList.remove('hovered');
          }
        });
        this.mouseOnDropdownLink($link);
      });

      $link.addEventListener('mouseleave', () => {
        timeout = setTimeout(() => {
          this.mouseOutDropdownLink($link);
        }, 50);
      });

      if ($link.subMenu) {
        $link.subMenu.addEventListener('mouseenter', () => {
          clearTimeout(timeout);
        });
        $link.subMenu.addEventListener('mouseleave', () => {
          timeout = setTimeout(() => {
            this.mouseOutDropdownLink($link);
          }, 50);
        });
      }

    });
  }

  mouseOnDropdownLink($link) {
    this.arDropdownLinks.forEach($item => {
      if ($link !== $item) {
        $item.classList.add('hovered');
      }
    });
    setTimeout(() => {
      this.arDropdownLinks.forEach($item => {
        if ($link !== $item) {
          $item.classList.add('hovered');
        }
      });
    }, 25);
    this.delayedAnimation($link.subMenu);
  }

  mouseOutDropdownLink($link) {
    this.arDropdownLinks.forEach($item => {
      if ($link !== $item) {
        $item.classList.remove('hovered');
      }
    });

    this.delayedAnimation($link.subMenu, true);
  }


  delayedAnimation($element, isHide = false) {
    if (!$element) {
      return false;
    }
    if (!isHide) {
      $element.style.display = 'block';
      setTimeout(() => {
        requestAnimationFrame(() => {
          $element.classList.add('active');
        });
      }, 50);
    } else {
      requestAnimationFrame(() => {
        $element.classList.remove('active');
      });
      setTimeout(() => {
        $element.style.display = 'none';
      }, this.transition);
    }
  }

}
