import axios from 'axios';
import Masonry from 'masonry-layout';
import $ from 'jquery';
import Inputmask from 'inputmask';
import fancybox from 'fancybox';
import 'bootstrap/js/dist/collapse';
import Header from '../templates/blocks/header/_class';
import Slider from '../templates/blocks/slider/_class';
import Up from '../templates/blocks/up/_class';
import Team from '../templates/blocks/team/_class';

document.addEventListener('DOMContentLoaded', () => {

  const TRANSITION_SPEED = 450;

  if ($('.b-selector__menu').length) {
    $(document.body).on('click', function (event) {
      if (!$(event.target).closest('.b-selector__menu').length && !$(event.target).closest('.b-selector__pseudo').length) {
        $('.collapse.show').collapse('hide');
      }
    });
  }

  let arPhones = document.body.querySelectorAll('.js-phone');

  const im = new Inputmask('+7 (999) 999-99-99');

  if (arPhones) {

    arPhones.forEach(phone => {
      im.mask(phone);
    });

  }

  let arHeaders = document.querySelectorAll('.b-header');

  if (arHeaders) {

    arHeaders.forEach($header => {

      $header.initedPlugin = new Header($header);

    });

  }

  let arCollapses = document.querySelectorAll('.js-collapse');

  if (arCollapses) {

    arCollapses.forEach($collapse => {

      let $title = $collapse.querySelector('.b-collapse__title');

      if ($title) {
        $title.addEventListener('click', (event) => {
          event.preventDefault();
          $title.classList.toggle('b-collapse__title_open');
          $collapse.classList.toggle('active');
        });
      }

    });

  }

  let arTeams = document.querySelectorAll('.b-team');

  if (arTeams) {

    arTeams.forEach($team => {

      $team.initedPlugin = new Team($team);

    });

  }

  let arUp = document.querySelectorAll('.js-go-up');

  if (arUp) {

    arUp.forEach($up => {

      $up.initedPlugin = new Up($up);

    });

  }

  let arLandingSliders = document.querySelectorAll('.b-landing');
  let arTeamSliders = document.querySelectorAll('.js-team-slider');

  if (arTeamSliders) {
    arTeamSliders.forEach($slider => {
      let slider = new Slider($slider.querySelector('.swiper-container'), {
        'slidesPerView': 3,
        'spaceBetween': 187,
        'slideToClickedSlide': 1,
        loop: true,
        breakpoints: {
          // when window width is >= 320px
          320: {
            slidesPerView: 1,
            spaceBetween: 30
          },
          // when window width is >= 480px
          480: {
            slidesPerView: 1,
            spaceBetween: 30
          },
          // when window width is >= 640px
          720: {
            slidesPerView: 1,
            spaceBetween: 30
          },
          920: {
            slidesPerView: 3,
            spaceBetween: 187
          }
        },
        navigation: {
          nextEl: $slider.querySelector('.js-next'),
          prevEl: $slider.querySelector('.js-prev'),
        },
        'autoplay': false,
        on: {
          slideChange: () => {

            setTimeout(() => {
              document.querySelectorAll('.hovered').forEach($item => {
                $item.classList.remove('hovered');
              });
            }, 100);


          },
        },
      });
    });
  }
  let arSailingSliders = document.querySelectorAll('.js-sailing-slider');

  if (arSailingSliders) {
    arSailingSliders.forEach($slider => {
      let slider = new Slider($slider.querySelector('.swiper-container'), {
        'slidesPerView': 3,
        'spaceBetween': 24,
        'slideToClickedSlide': 1,
        loop: true,
        breakpoints: {
          // when window width is >= 320px
          320: {
            slidesPerView: 1.3,
            spaceBetween: 14
          },
          // when window width is >= 480px
          480: {
            slidesPerView: 1.3,
            spaceBetween: 14
          },
          // when window width is >= 640px
          720: {
            slidesPerView: 2,
            spaceBetween: 14
          },
          920: {
            slidesPerView: 3,
            spaceBetween: 24
          }
        },
        navigation: {
          nextEl: $slider.querySelector('.js-next'),
          prevEl: $slider.querySelector('.js-prev'),
        },
        'autoplay': false
      });
    });
  }

  if (arLandingSliders) {

    arLandingSliders.forEach($slider => {
      let $title = $slider.querySelector('.js-slide-title'),
        $bg = $slider.querySelector('.js-bg'),
        $body = $slider.querySelector('.js-some-body'),
        $bgPrev = $slider.querySelector('.js-bg-prev'),
        $timeline = $slider.querySelector('.js-timeline'),
        $arBackLinks = $slider.querySelectorAll('.js-back-link'),
        $arChangeModals = $slider.querySelectorAll('.js-change-modal'),
        iDelay = 8000,
        timelineInterval,
        slider = new Slider($slider, {
          'slidesPerView': 'auto',
          'spaceBetween': 120,
          'slideToClickedSlide': 1,
          loop: true,
          breakpoints: {
            // when window width is >= 320px
            320: {
              slidesPerView: 1.5,
              spaceBetween: 20
            },
            // when window width is >= 480px
            480: {
              slidesPerView: 1.5,
              spaceBetween: 20
            },
            // when window width is >= 640px
            720: {
              slidesPerView: 2,
              spaceBetween: 20
            },
            920: {
              'slidesPerView': 'auto',
              'spaceBetween': 120,
            }
          },
          navigation: {
            nextEl: $slider.querySelector('.js-next'),
            prevEl: $slider.querySelector('.js-prev'),
          },
          'autoplay': {
            'delay': iDelay,
          },
          on: {
            init: () => {

              $timeline.classList.add('full');

            },
            slideChange: function () {
              let activeIndex = this.activeIndex,
                slide = this.slides[activeIndex];
              $title.setAttribute('data-target', slide.getAttribute('data-id'));
              if ($title.changeText) {
                $title.changeText(slide.getAttribute('data-title'), slide.getAttribute('data-bg'));
              }
              setTimeout(() => {
                this.autoplay.start();
              }, 1);
            },
            reachEnd: function () {

              this.loopFix();
              this.autoplay.stop();

              setTimeout(() => {
                this.slideToLoop(0);
                this.autoplay.start();
              }, 100);

            },
          }
        });

      let arClickToChangeLink = (event) => {

        event.preventDefault();

        let modal = document.querySelector('#' + $title.getAttribute('data-target')),
          activeModal = document.querySelector('.js-overlay.active');

        if (activeModal && activeModal !== modal) {
          activeModal.classList.remove('active');
        }

        modal.classList.add('active');
        $body.classList.add('hidden');
        slider.swiperContainer.swiper.autoplay.stop();

      };

      let arClickToHideLink = (event) => {

        event.preventDefault();

        let activeModal = document.querySelector('.js-overlay.active');

        if (activeModal) {
          activeModal.classList.remove('active');
        }

        $body.classList.remove('hidden');
        slider.swiperContainer.swiper.autoplay.start();
        slider.swiperContainer.swiper.slideNext();

      };

      $title.addEventListener('click', arClickToChangeLink);

      if ($arChangeModals) {

        $arChangeModals.forEach($link => {
          $link.addEventListener('click', event => {

            event.preventDefault();

            let modal = document.querySelector('#' + $link.getAttribute('data-target')),
              activeModal = document.querySelector('.js-overlay.active');

            if (activeModal && activeModal !== modal) {
              activeModal.classList.remove('active');
            }

            modal.classList.add('active');
            $body.classList.add('hidden');
            slider.swiperContainer.swiper.autoplay.stop();

          });
        });
      }

      if ($arBackLinks) {
        $arBackLinks.forEach($link => {
          $link.addEventListener('click', arClickToHideLink);
        });
      }

      $title.changeText = function (sText, sImageUrl) {

        $timeline.classList.remove('full');

        setTimeout(() => {
          $timeline.classList.add('full');
        }, 350);
        $title.classList.add('i-animated');
        document.querySelectorAll('.js-bg').forEach($bg => {
          $bg.classList.add('i-animated');
        });
        setTimeout(() => {
          let id = document.querySelector('.swiper-slide-active').getAttribute('data-id');

          document.querySelector('.js-bg-' + id).classList.remove('i-animated');
        }, 200);

        setTimeout(() => {
          $title.classList.remove('i-animated');
          $title.innerHTML = sText;
        }, 575);


      };
    });

  }

  let arErrors = document.querySelectorAll('.wpcf7-response-output.wpcf7-validation-errors');
  if (arErrors) {
    arErrors.forEach($error => {
      $error.innerHTML = '<span>' + $error.innerHTML + ' <br/><a href="/contacts/">Попробуйте еще раз</a>' + '</span>';
    });
  }

  let arSmallSlider = document.querySelectorAll('.js-small-clients-slider');
  if (arSmallSlider) {
    arSmallSlider.forEach($slider => {
      let trueLength = $slider.querySelectorAll('.swiper-slide').length,
        length = $('.b-article_service').length ? 4:7,
        slider = new Slider($slider.querySelector('.swiper-container'), {
          'slidesPerView': trueLength >= 4 ? length:trueLength,
          'spaceBetween': 30,
          'slideToClickedSlide': 1,
          observer: true,
          observeParents: true,
          observeSlideChildren: true,
          loop: trueLength > 4,
          centeredSlides: false,
          lazyLoading: true,
          lazyLoadingInPrevNext: true,
          breakpoints: {
            320: {
              'spaceBetween': 20,
              'slideToClickedSlide': 1,
              'slidesPerView': trueLength >= 4 ? 4:trueLength,
              loop: true,
            },
            987: {
              'spaceBetween': 30,
              'slideToClickedSlide': 1,
              'slidesPerView': trueLength >= 4 ? length:trueLength,
              loop: true,
            }
          },
          navigation: {
            nextEl: $slider.querySelector('.js-next'),
            prevEl: $slider.querySelector('.js-prev'),
          },
          'autoplay': false,
        });
      setTimeout(() => {
        slider.domElem.initedCarousel.update();
      }, 9000);

    });
  }

  let arXSSlider = document.querySelectorAll('.js-about-clients');
  if (arXSSlider) {
    arXSSlider.forEach($slider => {
      let slider = new Slider($slider.querySelector('.swiper-container'), {
        'slidesPerView': 1,
        'spaceBetween': 30,
        'slideToClickedSlide': 1,
        observer: true,
        observeParents: true,
        observeSlideChildren: true,
        loop: true,
        centeredSlides: false,
        lazyLoading: true,
        lazyLoadingInPrevNext: true,
        breakpoints: {
          320: {
            'spaceBetween': 20,
            'slideToClickedSlide': 1,
            'slidesPerView': 1,
            loop: true,
          },
          987: {
            'spaceBetween': 30,
            'slideToClickedSlide': 1,
            'slidesPerView': 1,
            loop: true,
          }
        },
        navigation: {
          nextEl: $slider.querySelector('.js-next'),
          prevEl: $slider.querySelector('.js-prev'),
        },
        'autoplay': false,
      });

    });
  }

});

window.onload = function () {

  let smoothScroll = document.querySelectorAll('.js-smooth-scroll');

  let grid = document.querySelector('.b-latest-news__row.flex-wrap'),
    msnry;

  if (grid) {
    msnry = new Masonry(grid, {
      itemSelector: '.js-column',
      gutter: 23
    });
  }

  if (smoothScroll) {

    let jsDecoration = document.querySelector('.js-decoration strong');

    smoothScroll.forEach(scroll => {
      let $scroll = scroll,
        $target = document.querySelector($scroll.getAttribute('href'));


      window.addEventListener('scroll', () => {
        var scrollPosition = document.documentElement.scrollTop || document.body.scrollTop,
          offset = $($target).offset().top, height = parseInt(offset) + $target.offsetHeight;
        if (offset < 0) {
          offset += 100;
        }

        if (scrollPosition > offset - 80 && scrollPosition <= height && !$scroll.classList.contains('current')) {
          let prevActive = document.querySelector('.js-smooth-scroll.current');
          if (prevActive) {
            prevActive.classList.remove('current');
          }
          $scroll.classList.add('current');
          jsDecoration.innerHTML = $scroll.textContent || $scroll.innerText;
        }

      });
      scroll.addEventListener('click', event => {
        event.preventDefault();
        console.log(event, $scroll);
        let target = document.querySelector($scroll.getAttribute('href'));
        if (target) {
          target.scrollIntoView({block: 'start', behavior: 'smooth'});
          let strongs = document.querySelectorAll('.js-smooth-scroll.current');
          if (strongs) {
            strongs.forEach(strong => {
              strong.classList.remove('current');
            });
          }
          scroll.classList.add('current');
        }
      });
    });
  }
};
