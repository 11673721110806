export default class {

  constructor(elem, param = {}) {
    this.domElem = elem;
    this.domElem.addEventListener('click', event => {
      event.preventDefault();
      let target = this.domElem.getAttribute('href');
      if (target==='#') {
        target = false;
      }
      this.smoothScroll(target);
    });

  }

  smoothScroll($elem) {
    $elem = document.querySelector($elem ? $elem:'.b-site');
    $elem.scrollIntoView({block: 'start', behavior: 'smooth'});
  }

}
